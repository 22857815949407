<template>
  <div>
    <v-card width="500" class="mx-auto">
      <v-card-title>
        <span class="headline">
          <b>الخطة السنوية</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex>
                <v-select
                  v-model="plan.year"
                  :items="years"
                  @change="getAnnualPlan"
                  label="السنة"
                  persistent-hint
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  :rules="plantotalRules"
                  label="الهدف السنوي"
                  placeholder="الهدف السنوي"
                  v-model="plan.total"
                  required
                  
                  type="targetyear"
                ></v-text-field>
              </v-flex>
              <v-divider></v-divider>
              <v-flex xs12 sm12>
                <v-text-field
                  label="هدف السعر"
                  placeholder="هدف السعر"
                  v-model="plan.price"
                 :rules="[
                              (v) => !!v || ' مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                          
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12>
                <label align="right">ايام العطل</label>
 
                <br />
                <br />

                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateoff"
                  attach=""
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox
                      v-model="dateoff"
                      multiple
                      chips
                      small-chips
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="planDaysoffRules"
                      v-bind="attrs"
                      v-on="on"
                      required
                    ></v-combobox>
                  </template>
               
                  <v-date-picker
                    v-model="dateoff"
                    :show-current="`${this.plan.year}-01-01`"
                    multiple
                    :min="mainDate"
                    :max="maxDate"
                    :always-display="false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save([])"
                      >Clear
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(dateoff)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout wrap row>
              <v-flex>
                <label align="right">الهدف الشهري</label>
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">الشهر</th>
                        <th class="text-center">الهدف</th>
                      </tr>
                    </thead>
                    <tbody v-if="plan.months.length > 0">
                      <tr v-for="(item, index) in plan.months" :key="index">
                        <td class="text-center">
                          <strong>{{ item.month1 }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            v-model="item.target"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-else>
                      <tr v-for="(month, index) in months" :key="index">
                        <td class="text-center">
                          <strong>{{ month.Month1 }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            v-model="month.Target"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap row>
              <v-flex>
                <label align="right">المناطق</label>
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">المنطقة</th>
                        <th class="text-center">الهدف</th>
                      </tr>
                    </thead>
                    <tbody v-if="plan.regions.length > 0">
                      <tr v-for="(item, index) in plan.regions" :key="index">
                        <td class="text-center">
                          <strong>{{ item.name }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            v-model="item.target"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-else>
                      <tr v-for="(item, index) in Allarea" :key="index">
                        
                        <td class="text-center">
                          <strong>{{ item.Name }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            v-model="item.Target"
                            :rules="TargetRules"
                            >{{ item.Target }}</v-text-field
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap row>
              <v-flex>
                <label align="right">النشاطات</label>
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">النشاط</th>
                        <th class="text-center">الهدف</th>
                      </tr>
                    </thead>
                    <tbody v-if="plan.activities.length > 0">
                      <tr v-for="(item, index) in plan.activities" :key="index">
                        <td class="text-center">
                          <strong>{{ item.name }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            v-model="item.target"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-else>
                      <tr
                        v-for="(item, index) in customersActivity"
                        :key="index"
                      >
                        <td class="text-center">
                          <strong>{{ item.name }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            v-model="item.Target"
                            >{{ item.Target }}</v-text-field
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap row>
              <v-flex>
                <label align="right">المنتجات</label>
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">المنتج</th>
                        <th class="text-center">الهدف</th>
                      </tr>
                    </thead>
                    <tbody v-if="plan.products.length > 0">
                      <tr v-for="(item, index) in plan.products" :key="index">
                        <td class="text-center">
                          <strong>{{ item.productNumber }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            v-model="item.target"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-else>
                      <tr v-for="(item, index) in products" :key="index">
                        <td class="text-center">
                          <strong>{{ item.productname }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            v-model="item.Target"
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            >{{ item.target }}</v-text-field
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap row>
              <v-flex>
                <label align="right">الزبائن</label>
                <v-divider></v-divider>

                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">الزبون</th>
                        <th class="text-center">الهدف</th>
                      </tr>
                    </thead>
                    <tbody v-if="customersName.length > 0">
                      <tr v-for="(item, index) in customersName" :key="index">
                        <td class="text-center">
                          <strong>{{ item.name }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            v-model="item.target"
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            >{{ item.target }}</v-text-field
                          >
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-else>
                      <tr v-for="(item, index) in customers" :key="index">
                        <td class="text-center">
                          <strong>{{ item.customerName }}</strong>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            v-model="item.Target"
                            :rules="[
                              (v) => !!v || 'الهدف مطلوب',
                              (v) =>
                                (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                            ]"
                            >{{ item.Target }}</v-text-field
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!planId"
            @click="save"
            style="background: #5867dd; color: white; margin-left: 5px"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <v-btn
            v-else
            @click="edit"
            style="background: #5867dd; color: white; margin-left: 5px"
            dark
          >
            <b>تعديل الخطة</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background: gray" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
// import Vue from "vue";
// import  required  from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "plan",

  data: () => ({
    valid: true,
    dates: [],
    id: "",
    multi: true,
    lang: "en",
    test: [],
    customers: [],
    planId: false,
    modal: false,
    modal2: false,
    dateoff: [],
    menu: false,
    off: [],
    TargetRules: [],
    year: "",
    totalActivity: 0,
    totalMonths: 0,
    totalRegion: 0,
    plantotalRules: [],
    planpriceRules: [],
    planDaysoffRules: [],
    Allarea: [],

    years: [],

    months: [
      { Month1: " يناير", Target: "" },
      { Month1:  "فبراير", Target: "" },
      { Month1: "مارس", Target: "" },
      { Month1: "أبريل", Target: "" },
      { Month1: "مايو", Target: "" },
      { Month1:"يونيو", Target: "" },
      { Month1: "يوليو", Target: "" },
      { Month1: "أغسطس", Target: "" },
     
      { Month1:  "سبتمبر", Target: "" },
      { Month1:"أكتوبر", Target: "" },
         { Month1:"نوفمبر", Target: "" },
      { Month1: "ديسمبر", Target: "" },
    ],
    Activities: [
      {
        ActivityId: 0,
        Target: 0,
      },
    ],
    customersActivity: [],
    mainDate: "",
    maxDate: "",
    currentDate: "",
    plan: {
      fullPlan: "",
      price: "",
      total: "",
      month: "",
      year: "",
      target: "",
      activities: [],
      customers: [],
      months: [],
      regions: [],
      daysOff: [],
      products: [],
    },
    products: [],
    customersName: [],
    totalCustomer: "",
    totalPtoduct: "",
  }),

  watch: {
    // dateoff() {
    //   this.planDaysoffRules = [];
    // },
    "plan.price"() {
      this.planpriceRules = [];
    },
    "plan.year"() {
      this.plantotalRules = [];
    },
  },

  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    var d = new Date();
    // var date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();

    this.getYears();
    this.getCustomers();
    this.getProduct();
    this.getAllArea();
    this.getListByActivity();
  },

  //  computed: {
  //     rules () {
  //       const rules = []
  //       if(this.plan.year){

  //         const rule =
  //           v => !!v ||
  //             `السعر مطلوب`
  //         rules.push(rule)

  //       }
  //         return rules
  //     },

  //   },

  methods: {
    async getListByActivity() {
      const response = await this.ApiService.get("Activity/List");

      response.data.responseData.forEach((el) => {
        this.customersActivity.push({ name: el.name, id: el.id });
      });
    },
    async getAllArea() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/YearReport/ListAreas"
      );
      response.data.responseData.forEach((el) => {
        this.Allarea.push({ Name: el.name, Target: "",id:el.id });
      });
    },
    async getCustomers() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/api/getreportService/getAllCustomers"
      );
      response.data.responseData.forEach((el) => {
        if (
          el.customerNumber == 36 ||
          el.customerNumber == 40 ||
          el.customerNumber == 95 ||
          el.customerNumber == 153 ||
          el.customerNumber == 3663 ||
          el.customerNumber == 6081 ||
          el.customerNumber == 6106 ||
          el.customerNumber == 6206 ||
          el.customerNumber == 9498 ||
          el.customerNumber == 6331 ||
          el.customerNumber == 9496 ||
          el.customerNumber == 6454 ||
          el.customerNumber == 6105 ||
          el.customerNumber == 91 ||
          el.customerNumber == 6443
        )
          this.customers.push({
            CustomerId: el.customerNumber,
            Target: "",
            customerName: el.customerName,
          });
      });
    },
    async getProduct() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/api/getreportService/getAllProducts"
      );

      response.data.responseData.forEach((el) => {
        if (!(el.productNumber.indexOf("SCR") > -1)) {
          this.products.push({
            ProductNumber: el.productNumber,
            Target: "",
            productname: el.productname,
          });
        }
      });
    },
    getTotal() {
      this.totalActivity = 0;
      this.totalMonths = 0;
      this.totalRegion = 0;
      this.totalCustomer = 0;
      this.totalPtoduct = 0;
      this.plan.activities.forEach((el) => {
        let x = parseInt(el.target);

        this.totalActivity += x;
      });
      this.plan.months.forEach((el) => {
        let x = parseInt(el.target);
        this.totalMonths += x;
      });
      this.plan.regions.forEach((el) => {
        let x = parseInt(el.target);
        this.totalRegion += x;
      });
      this.customersName.forEach((el) => {
        let x = parseInt(el.target);
        this.totalCustomer += x;
      });
      this.plan.products.forEach((el) => {
        let x = parseInt(el.target);
        this.totalPtoduct += x;
      });
      // console.log(this.totalActivity);
      // console.log(this.totalMonths);
      // console.log(this.totalRegion);
      // console.log(this.totalCustomer);
      // console.log(this.totalPtoduct);
    },
    getTotalForAdd() {
      this.totalActivity = 0;
      this.totalMonths = 0;
      this.totalRegion = 0;
      this.totalCustomer = 0;
      this.totalPtoduct = 0;

      this.customersActivity.forEach((el) => {
        let x = parseInt(el.Target);

        this.totalActivity += x;
      });
      this.customers.forEach((el) => {
        let x = parseInt(el.Target);

        this.totalCustomer += x;
      });
      this.plan.months.forEach((el) => {
        let x = parseInt(el.target);
        this.totalMonths += x;
      });
      this.plan.regions.forEach((el) => {
        let x = parseInt(el.target);
        this.totalRegion += x;
      });
      this.products.forEach((el) => {
        let x = parseInt(el.Target);
        this.totalPtoduct += x;
      });
      // console.log(this.totalActivity);
      // console.log(this.totalMonths);
      // console.log(this.totalRegion);
      // console.log(this.totalCustomer);
      // console.log(this.totalPtoduct);
    },
    getYears() {
      let y = 0;
      while (y < 10) {
        this.years.push(this.year + y);
        y++;
      }
    },
    formatDate(x) {
      var datePart = x.match(/\d+/g),
        year = datePart[0].substring(4), // get only two digits
        month = datePart[1],
        day = datePart[2];
      let y = day + "-" + month + "-" + year;
      // console.log("y", y);
      return y;

      // formatDate(date) {
    },
    reset() {
      this.dates = [];
      this.plan = null;
      this.$refs.form.reset();
    },
    edit() {
      this.getTotal();
      if (this.dateoff.length == 0) {
        this.planDaysoffRules = ["ايام العطل مطلوبة"];
      }
      else{
         this.planDaysoffRules = []
      }
      this.plantotalRules = [
        (v) => !!v || "الهدف السنوي مطلوب",
        (v) =>
          (v && v >= this.totalMonths) || " الهدف السنوي اعلى من اهداف الاشهر",
        (v) =>
          (v && v >= this.totalCustomer) ||
          " الهدف السنوي اعلى من اهداف الزبائن",
        (v) =>
          (v && v >= this.totalPtoduct) ||
          " الهدف السنوي اعلى من اهداف المنتجات",
        (v) =>
          (v && v >= this.totalActivity) ||
          " الهدف السنوي اعلى من اهداف النشاطات",
        (v) =>
          (v && v >= this.totalRegion) || " الهدف السنوي اعلى من اهداف المناطق",
        (v) => (v && /^[0-9]*$/.test(v)) || "لا يحوي محارف",
      ];
      this.planpriceRules = [
        (v) => !!v || "هدف السعر مطلوب",
        (v) => (v && /^[0-9]*$/.test(v)) || "لا يحوي محارف",
        (v) => (v && v.length < 25) || "الاسم لا يجب أن يكون اكثر من 25 رقم",
      ];

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          let days = [];
          this.dateoff.forEach((e) => {
            if (e != null) {
              days.push({ Day: e });
            }
          });
          let customer = [];
          this.customersName.forEach((el) => {
            customer.push({ CustomerId: el.customerId, Target: el.target });
          });
          let products = [];
          this.plan.products.forEach((el) => {
            products.push({
              ProductNumber: el.productNumber,
              Target: el.target,
            });
          });
          let Activity = [];
          this.plan.activities.forEach((el) => {
            Activity.push({ ActivityId: el.id, Target: el.target });
          });
          let regions = [];
          this.plan.regions.forEach((el) => {
            regions.push({ name: el.name, Target: el.target });
          });
          let months = [];
          this.plan.months.forEach((el) => {
            months.push({ Month1: el.month1, Target: el.target });
          });

          let data = {
            Year: this.plan.year,
            Total: this.plan.total,
            Price: this.plan.price,

            Activities: Activity,
            months: months,
            regions: regions,
            Customers: customer,
            Products: products,
            daysOffs: days,
          };

          let url = null;
          url = `AnnualPlan/Update?id=${this.id}`;
          this.ApiService.put(url, data)
            .then(() => {
              this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            })
            .catch(() => {
              this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
            });
        }
      });
    },

    save() {
      this.planDaysoffRules = [];
      this.getTotalForAdd();

      if (this.dateoff.length == 0) {
        this.planDaysoffRules = ["ايام العطل مطلوبة"];
      }
      this.plantotalRules = [
        (v) => !!v || "الهدف السنوي مطلوب",
        (v) =>
          (v && v >= this.totalMonths) || " الهدف السنوي اعلى من اهداف الاشهر",
        (v) =>
          (v && v >= this.totalCustomer) ||
          " الهدف السنوي اعلى من اهداف الزبائن",
        (v) =>
          (v && v >= this.totalPtoduct) ||
          " الهدف السنوي اعلى من اهداف المنتجات",
        (v) =>
          (v && v >= this.totalActivity) ||
          " الهدف السنوي اعلى من اهداف النشاطات",
        (v) =>
          (v && v >= this.totalRegion) || " الهدف السنوي اعلى من اهداف المناطق",
        (v) => (v && /^[0-9]*$/.test(v)) || "لا يحوي محارف",
      ];
      this.planpriceRules = [
        (v) => !!v || "هدف السعر مطلوب",
        (v) => (v && /^[0-9]*$/.test(v)) || "لا يحوي محارف",
      ];
      this.TargetRules = [(v) => !!v || "الهeدف مطلوب"];
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          let days = [];
          this.dateoff.forEach((e) => {
            if (e != null) {
              days.push({ Day: e });
            }
          });

          let customer = [];
          this.customers.forEach((el) => {
            customer.push({ CustomerId: el.CustomerId, Target: el.Target });
          });
          let products = [];
          this.products.forEach((el) => {
            products.push({
              ProductNumber: el.ProductNumber,
              Target: el.Target,
            });
          });
          let Activity = [];
          this.customersActivity.forEach((el) => {
            Activity.push({ ActivityId: el.id, Target: el.Target });
          });
          let regions = [];
          this.plan.regions.forEach((el) => {
            regions.push({ name: el.name, Target: el.target });
          });
          let months = [];
          this.plan.months.forEach((el) => {
            months.push({ Month1: el.month1, Target: el.target });
          });
          let data = {
            Year: this.plan.year,
            Total: this.plan.total,
            Price: this.plan.price,

            Activities: Activity,
            months: months,
            regions: regions,
            Customers: customer,
            Products: products,
            daysOffs: days,
          };

          let url = null;

          url = `AnnualPlan/Add`;
          this.ApiService.post(url, data)
            .then(() => {
           
              this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            })
            .catch(() => {
              this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
            });
        }
      }, 1000);
      // },
    },

    getAnnualPlan() {
      this.currentDate = "";
      this.mainDate = `${this.plan.year}-01-01`;

      this.currentDate = `${this.plan.year}-01-01`;

      this.maxDate = `${this.plan.year}-12-31`;
      this.dateoff = [];
      // this.plan.activities=[]

      //   this.plan.customers=[]
      //     this.plan.months=[]
      //       this.plan.regions=[]
      //          this.plan.products=[]
      this.customersName = [];

      this.ApiService.get("AnnualPlan/GetByYear?year=" + this.plan.year).then(
        (res) => {
          this.plan = res.data.responseData;
          this.id = res.data.responseData.idannualPlan;
          if (this.id != 0) {
            this.planId = true;
          }
          this.off = res.data.responseData.daysOffs;

          // console.log("plan", res.data.responseData)
          this.customers.forEach((el) => {
            this.plan.customers.forEach((ele) => {
              if (el.CustomerId == ele.customerId) {
                this.customersName.push({
                  name: el.customerName,
                  target: ele.target,
                  customerId: ele.customerId,
                });
              }
            });
          });

          if (this.off != null) {
            this.off.forEach((e) => {
              let x = moment(String(e.day)).format("YYYY-MM-DD");

              this.dateoff.push(x);
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped>
label {
  /* margin-top: 2px; */
  font-size: 20px;
  color: #266;
  margin-left: 15px;
}
</style>
